import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import dateFormat from 'dateformat'
import Barcode from 'react-barcode'
import EscalationIndicator from './EscalationIndicator'
import moment from 'moment'

const styles = theme => ({
  root: theme.root,
  text: theme.typography.text.small,
  alignTop: {
    alignSelf: 'flex-start'
  },
  alignBottom: {
    alignSelf: 'flex-end'
  },
  barCode: theme.shape.barCode,
  divContainer: {
    display: 'flex'
  },
  indicatorColumn: {
    maxWidth: theme.spacing(30)
  },
  priceType: {
    whiteSpace: 'nowrap',
    color: 'red',
    flex: 1
  }
})

export function ItemPanel ({ item, classes }) {
  let multiLineValue = item.isMLMDOrder === 'TRUE' ? `(${item.multiLineItemNumber}/${item.orderLineItemCount})` : ''
  const escalationIndicatorComponent = item.escalationLevel
    ? <EscalationIndicator
      itemId={item.id}
      escalationLevel={item.escalationLevel}
      escalationMetadata={item.escalationMetadata} />
    : null
  const itemInfo = [
    { children: renderDueByDate(), gridItemSize: 1, gridItemXSSize: 12 },
    { children: renderBarCode(item.orderId, 'CODE128', multiLineValue, 1.25), gridItemSize: 2, gridItemXSSize: 12 },
    { children: item.brand, gridItemSize: 1, gridItemXSSize: 12 },
    { children: item.itemName, gridItemSize: 2 , gridItemXSSize: 12 },
    { children: renderBarCode(item.upc, 'CODE128', item.isAnniversary ? '(A)' : undefined), gridItemSize: 2, gridItemXSSize: 12 },
    { children: item.size, gridItemSize: 1 , gridItemXSSize: 12 },
    { children: item.color, gridItemSize: 1, gridItemXSSize: 12 },
    { children: renderStorePriceInfo(), gridItemSize: 1, gridItemXSSize: 12 },
    { children: escalationIndicatorComponent, gridItemSize: 1, gridItemXSSize: 12 }
  ]

  function buildClassName (key) {
    if (key === 0) {
      return `${classes.text} ${classes.alignTop}`
    }
    if (key === itemInfo.length - 1 || key === itemInfo.length - 2) {
      return `${classes.text} ${classes.alignBottom} ${classes.indicatorColumn}`
    }
    return `${classes.text} ${classes.alignBottom}`
  }
  function renderGridItem ({ children, gridItemSize, gridItemXSSize}, key) {
    return <Grid item xs={gridItemXSSize} sm={gridItemSize} className={buildClassName(key)} key={key}>{children}</Grid>
  }
  function renderBarCode (value, format, multiLineValue, width) {
    return (
      <div className={classes.barCode} class='mobile-spacing'>
        <div className={classes.divContainer}>{value}<div className={'item-flags'} style={{fontWeight: 'bold', marginLeft: '10px'}}>{multiLineValue}</div></div>
        <Barcode
          width={width || 1}
          height={24}
          value={value}
          format={format}
          displayValue={false}
          textAlign='center'
          textPosition='bottom'
          margin={0}
        />
      </div>
    )
  }
  moment.updateLocale('en', {
    calendar : {
        lastDay : '[Yesterday]',
        sameDay : '[Today]',
        nextDay : '[Tomorrow]',
        lastWeek: 'M/D/YY',
        nextWeek: 'M/D/YY',
        sameElse : 'M/D/YY'
    }
  })
  function renderDueByDate () {
    let dueByDate = item.dueByDate
    if (!item.dueByDate) {
      dueByDate = item.expiresDate
    } 
    const time = dateFormat(dueByDate, 'h:MM TT')
    const date = moment(dueByDate).calendar()
    return (
      <div className={classes.text}>
        <strong>{time}</strong>
        <div>{date}</div>
      </div>
    )
  }
  function renderItemInfo () {
    return itemInfo.map((info, index) => (renderGridItem(info, index)))
  }
  return (
    <Grid container  direction='row' justify='space-between' alignItems='center' className={'item-panel'}>
      {renderItemInfo()}
    </Grid>
  )

  function renderStorePriceInfo () {
    const { priceType, priceTypeDescription, storePrice } = item
    if (!priceType || priceType === 'R') {
      return (
        <div className={classes.text}>
          {`$${storePrice}`}
        </div>
      )
    }
    if (priceTypeDescription.split(' ').length - 1) {
      return (
        <div className={`${classes.text} ${classes.priceType}`}>
          <div>
            {`(${priceTypeDescription.split(' ')[0]}`}
          </div>
          <div>
            {`${priceTypeDescription.split(' ').slice(1).join(' ')})`}
          </div>
          <div>
            {`$${storePrice}`}
          </div>
        </div>
      )
    }
    return (
      <div className={`${classes.text} ${classes.priceType}`}>
        <div>
          {`(${priceTypeDescription})`}
        </div>
        <div>
          {`$${storePrice}`}
        </div>
      </div>
    )
  }
}
ItemPanel.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    orderId: PropTypes.string.isRequired,
    expiresDate: PropTypes.string.isRequired,
    dueByDate: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired,
    upc: PropTypes.string.isRequired,
    itemName: PropTypes.string.isRequired,
    color: PropTypes.string,
    size: PropTypes.string,
    storePrice: PropTypes.string,
    isMLMDOrder: PropTypes.string,
    isAnniversary: PropTypes.bool,
    escalationLevel: PropTypes.string,
    escalationMetadata: PropTypes.shape({
      escalatedBy: PropTypes.shape({
        employeeFirstName: PropTypes.string,
        employeeLastName: PropTypes.string,
        employeeId: PropTypes.string
      }),
      escalatedAt: PropTypes.string
    }),
    priceType: PropTypes.string,
    priceTypeDescription: PropTypes.string
  }).isRequired
}

export default withStyles(styles)(ItemPanel)