import timeFormatter from './time'
import filter from './filter'
const utils = {timeFormatter,
  filter}
export {timeFormatter, filter}

export const pluralize = (noun, count) => {
  if (count === 1) {
    return noun
  }
  return noun + 's'
}

export default utils
