import Body from './Components/Body'
import Container from '@material-ui/core/Container'
import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useParams } from "react-router-dom"
import { fetchFullLineStores } from '../../Services/api'

export function PicklistNav () {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  const classes = useStyles();
  let { storeNumber, orderType } = useParams();
  return (
    <Container maxWidth="lg" className={classes.container}>
      <Body storeNumber={storeNumber} orderType={orderType} getStores={fetchFullLineStores}/>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(8, 15, 6),
  },
  orderHeaderContainer: {
    marginBottom: 20
  },
  typographyPad: {
    paddingBottom: 10
  }
}));
