import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import ReportHeader from './ReportHeader'
import ReportBody from './ReportBody'
import PropTypes from 'prop-types'
import { filter } from '../../../Services/util'

export const ALL_DEPARTMENTS = 'All Departments'
export class PickList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      departmentName: ALL_DEPARTMENTS,
      filters: [],
      isExpanded: true
    }
    this.departmentNames = this.filterDepartmentNames()
    this.onChangeDepartment = this.onChangeDepartment.bind(this)
  }
  onToggleExpand = () => {
    this.setState({ isExpanded: !this.state.isExpanded })
  }
  onChangeDepartment (departmentName) {
    this.setState({ departmentName })
    console.log(`Setting departmentName: ${departmentName}`)
  }
  getFilteredPickTasks () {
    if (this.state.filters.length === 0) {
      // No filter has been selected return default pickTasks
      return this.props.pickTasks
    } else {
      // return filtered pickTasks
      return filter.byPickTasks(this.props.pickTasks, filter.getFilterFuncList(this.state.filters))
    }
  }
  renderReport () {
    return this.getFilteredPickTasks().orderTypes.map((itemsBaseOnOrderType, index) => {
      const isLastReport = index === this.props.pickTasks.orderTypes.length - 1
      return <ReportBody
        {...itemsBaseOnOrderType}
        key={index}
        isLastReport={isLastReport}
        whitelist={{ departmentName: this.state.departmentName.toUpperCase() }}
        timestamp={this.props.timestamp}
        isExpanded={this.state.isExpanded}
      />
    })
  }
  filterDepartmentNames () {
    const departmentNames = []
    this.props.pickTasks.orderTypes.forEach(orderType => {
      orderType.departments.forEach(department => {
        if (departmentNames.findIndex(deptName => deptName === department.name) <= -1) {
          departmentNames.push(department.name)
        }
      })
    })
    return [ ALL_DEPARTMENTS ].concat(departmentNames.sort())
  }
  getTotalOrderCount () {
    let orderCount = 0
    this.props.pickTasks.orderTypes.forEach(orderType => {
      orderType.departments.forEach(department => {
        if (this.state.departmentName === ALL_DEPARTMENTS || this.state.departmentName === department.name) {
          orderCount += parseInt(department.orderCount, 10)
        }
      })
    })
    return orderCount
  }
  getTotalItemCount () {
    let count = 0
    this.props.pickTasks.orderTypes.forEach(orderType => {
      orderType.departments.forEach(department => {
        if (this.state.departmentName === ALL_DEPARTMENTS || this.state.departmentName === department.name) {
          count += department.count
        }
      })
    })
    return count
  }
  getSelectedFilters () {
    if (Object.keys(this.state.filters).length === 0) {
      return null
    }
    return this.state.filters
  }
  getFilteredItemCount () {
    return this.getFilteredPickTasks().count
  }
  setFilters (filters) {
    this.setState({ filters })
  }
  render () {
    const { storeNumber, storeName, orderType } = this.props
    return (
      <div className={this.props.classes.container}>
        <ReportHeader
          storeNumber={storeNumber}
          storeName={storeName}
          orderType={orderType}
          totalOrderCount={this.getTotalOrderCount()}
          totalItemCount={this.getTotalItemCount()}
          onChange={this.onChangeDepartment}
          departments={this.departmentNames}
          getFilters={this.setFilters.bind(this)}
          timestamp={this.props.timestamp}
          onToggleExpand={this.onToggleExpand}
          isExpanded={this.state.isExpanded}
          selectedFilters={this.getSelectedFilters()}
          filteredItemCount={this.getFilteredItemCount()}
        />
        {this.renderReport()}
      </div>
    )
  }
  componentDidMount () {
    this.props.onFinishedRendering()
  }
}

PickList.propTypes = {
  storeNumber: PropTypes.string,
  orderType: PropTypes.string,
  storeName: PropTypes.string,
  totalItemCount: PropTypes.number,
  pickTasks: PropTypes.shape({
    name: PropTypes.string,
    count: PropTypes.number,
    orderTypes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
        departments: PropTypes.arrayOf(
          PropTypes.shape({
            count: PropTypes.number.isRequired,
            departmentId: PropTypes.string,
            name: PropTypes.string.isRequired,
            items: PropTypes.arrayOf(
              PropTypes.shape({
                id: PropTypes.string.isRequired,
                orderId: PropTypes.string.isRequired,
                expiresDate: PropTypes.string.isRequired,
                brand: PropTypes.string.isRequired,
                upc: PropTypes.string.isRequired,
                itemName: PropTypes.string.isRequired,
                color: PropTypes.string,
                size: PropTypes.string,
                storePrice: PropTypes.string,
                isAnniversary: PropTypes.bool
              })
            )
          })
        )
      })
    )
  }),
  getFilters: PropTypes.func,
  timestamp: PropTypes.string.isRequired
}

const styles = theme => ({
  root: theme.typography.root,
  container: {
    ...theme.typography.container,
    flexDirection: 'column',
    paddingTop: theme.spacing(29)
  }
})

export default withStyles(styles)(PickList)
