import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import GroupHeader from './GroupHeader'
import SubgroupHeader from './SubgroupHeader'
import ItemList from './ItemList'
import PropTypes from 'prop-types'
import { ALL_DEPARTMENTS } from './PickList'

const styles = theme => ({
  root: {
    ...theme.typography.root,
    paddingTop: theme.spacing(20)
  },
  departmentList: {
    flex: 1,
    marginTop: theme.spacing(10)
  },
  subgroupHeader: {
    ...theme.typography.text.small,
    marginBottom: theme.spacing(10)
  }
})

export function ReportBody ({ name, count, departments, classes, whitelist, isLastReport, isExpanded, timestamp }) {

  const nshipIndex = name.indexOf('NSHIP')
  if (nshipIndex >= 0) {
    name = 'FREE NEXT DAY DELIVERY (NSHIP)'
  }

  const renderGroupHeaderText = () => {
    let itemCount = 0, orderCount = 0
    departments.forEach(department => {
      if (isWhitelisted(department)) {
        itemCount += department.count
        orderCount += (new Set(department.items.map(item => item.orderId))).size
      }
    })
    return `${name.replace(/_/g, ' ')} | ${orderCount} Order${(orderCount > 1) ? 's' : ''} | ${itemCount} item${itemCount > 1 ? 's' : ''}`
  }
  const renderPageBreak = (isLastDepartment) => {
    if (isLastReport && isLastDepartment) {
      return null
    }
    return <div>
      <div className='end-of-page'/>
      <div className='white-space'>&nbsp;</div>
    </div>
  }
  /**
   * Either the department.name is undefined, or it matches the departmentName from whitelist
   * @param name departmentName
   * @return {boolean}
   */
  const isWhitelisted = ({ name }) => {
    return whitelist.departmentName.toUpperCase() === ALL_DEPARTMENTS.toUpperCase() || whitelist.departmentName.toUpperCase() === name.toUpperCase()
  }
  const renderSubgroupHeader = (department) => {
    return <SubgroupHeader
      departmentName={department.name}
      departmentCount={department.count}
      orderType={name}
      timestamp={timestamp}/>
  }
  const renderDepartmentList = (isListExpanded) => {
    const { departmentList, subgroupHeader } = classes
    return departments.map((department, index) => {
      if (isWhitelisted(department)) {
        return (
          <div className={departmentList} key={index}>
            <div className={subgroupHeader}>{renderSubgroupHeader(department)}</div>
            {isListExpanded && <ItemList items={department.items}/>}
            {isListExpanded && renderPageBreak(index === departments.length - 1)}
          </div>
        )
      }
      return null
    })
  }
  /**
   * Display GroupHeader only if there is at least one department name matches the whitelist
   * @return {null, GroupHeader}
   */
  const renderGroupHeader = () => {
    const filteredDepartmentNames = departments.filter(department => isWhitelisted(department))
    return filteredDepartmentNames.length > 0 ? <GroupHeader>{renderGroupHeaderText()}</GroupHeader> : null
  }

  return (
    <div className={classes.root}>
      {renderGroupHeader()}
      {renderDepartmentList(isExpanded)}
    </div>
  )
}

ReportBody.propTypes = {
  name: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  whitelist: PropTypes.shape({
    departmentName: PropTypes.string
  }),
  departments:  PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number.isRequired,
      departmentId: PropTypes.string,
      name: PropTypes.string.isRequired,
      orderCount: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          orderId: PropTypes.string.isRequired,
          expiresDate: PropTypes.string.isRequired,
          brand: PropTypes.string.isRequired,
          upc: PropTypes.string.isRequired,
          itemName: PropTypes.string.isRequired,
          color: PropTypes.string,
          size: PropTypes.string,
          storePrice: PropTypes.string
        })
      )
    })
  ),
  isLastReport: PropTypes.bool.isRequired,
  timestamp: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired
}

export default withStyles(styles)(ReportBody)
