import React from 'react';
import { Grid, FormLabel, FormControl, Button } from '@material-ui/core'
import { useHistory } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles'
import DropDownMenu from '../../../Components/DropDownMenu'
import { ORDER_TYPE_LABELS, getOrderTypeLabels} from '../../../Services/api'

const FORM_LABEL_STORE = '1. Store'
const FORM_LABEL_ORDER_TYPE = '2. Order Type'
const INPUT_LABEL_STORE = 'Select a store'
const INPUT_LABEL_ORDER_TYPE = 'Select an order type'

export class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      storeNumber: this.props.storeNumber || null,
      orderType: this.props.orderType || null,
      resetInputs: false,
      orderTypeLabels: ORDER_TYPE_LABELS
    }
    this.clearAll = this.clearAll.bind(this)
    this.updateLabels()
  }
  async updateLabels () {
    const label = await getOrderTypeLabels()
    this.setState({orderTypeLabels: label})
  }
  setStoreNumber(storeNumber) {
    this.setState({ storeNumber: `${storeNumber}` })
    if (this.props.selectStoreOrOrderTypeNav) {
      this.props.selectStoreOrOrderTypeNav(storeNumber, this.state.orderType)
    }
  }
  setOrderType(orderType) {
    this.setState({ orderType: orderType })
    if (this.props.selectStoreOrOrderTypeNav) {
      this.props.selectStoreOrOrderTypeNav(this.state.storeNumber, orderType)
    }
  }
  clearAll() {    
    this.setState({ resetInputs: !this.state.resetInputs, storeNumber: null, orderType: null })
    if (this.props.onClearNav) {
      this.props.onClearNav()
    }
    this.props.onClear()
  }
  renderSubmitButton() {
    return (<Button type='submit' variant='contained' disableRipple={true} className={this.props.classes.button}>
      View Results
    </Button>)
  }
  renderResetButton() {
    return (<Button disableRipple={true} className={this.props.classes.link} onClick={this.clearAll}>
      Clear all
    </Button>)
  }

  renderButtons = () => (
    <Grid item>
      <FormControl variant="filled" className={this.props.classes.buttonsWrapper}>
        {this.renderSubmitButton()}
        {this.renderResetButton()}
      </FormControl>
    </Grid>
  )
  
  renderStoreDropdown () {
    return this.renderFilter(this.formatStores(), FORM_LABEL_STORE, INPUT_LABEL_STORE, this.setStoreNumber.bind(this), this.props.classes.dropDownMenu, this.state.storeNumber)
  }
  
  renderOrderTypeDropdown () {
    return this.renderFilter(this.formatOrderTypes(), FORM_LABEL_ORDER_TYPE, INPUT_LABEL_ORDER_TYPE, this.setOrderType.bind(this), this.props.classes.secondDropDownMenu, this.state.orderType)
  }
  
  renderFilter = (data, formLabel, inputLabel, onChange, className, selectedValue) => {
    return (
      <FormControl variant="filled" className={this.props.classes.formControl}>
        <FormLabel component="legend" className={this.props.classes.formLabel}>{formLabel}</FormLabel>
        <Grid container className={this.props.classes.dropDownContainer}>
          <DropDownMenu
            data={data}
            placeHolder={inputLabel}
            onChange={onChange.bind(this)}
            className={className}
            resetInputs={this.state.resetInputs}
            selected={selectedValue}
          />
        </Grid>
      </FormControl>
    )
  }

  /**
   * Get the subset of the state with values:  storeNumber, orderType
   * @return { storeNumber: '5', orderType: 'BOPUS' }
   */
  getPayload = (event) => {
    event.preventDefault()
    const { storeNumber, orderType } = this.state
    return { storeNumber, orderType }
  }
  formatStores = () => (
    this.props.stores.map(store => ({value: store.storeNumber, label: `${store.storeNumber} - ${store.storeName}`}))
  )
  formatOrderTypes = () => (
    this.props.orderTypes.map((orderType, idx) => ({value: orderType, label: this.state.orderTypeLabels[idx]}))
  )
  render() {
    const { onSubmit, classes } = this.props
    const { container } = classes
    return (
      <form className={container} autoComplete="off" onSubmit={(e) => onSubmit(this.getPayload(e))}>
        <Grid container justify="space-between" alignItems="flex-end" direction="row">
          <Grid item>
            {this.renderStoreDropdown()}
            {this.renderOrderTypeDropdown()}
          </Grid>
          {this.renderButtons()}
        </Grid>
      </form>
    )
  }
}

const styles = theme => ({
  container: theme.typography.container,
  formControl: {
    minWidth: 120,
  },
  formLabel: theme.typography.form.formLabel,
  buttonsWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    color: theme.color.primary,
    marginBottom: theme.spacing(9),
    marginTop: theme.spacing(9),
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(10)
  },
  button: theme.typography.button.primary,
  link: {
    ...theme.typography.button.link,
    marginLeft: theme.spacing(20)
  },
  dropDownContainer: {
    position: 'relative'
  },
  dropDownMenu: theme.typography.dropDownMenu.selectField,
  secondDropDownMenu: {
    ...theme.typography.dropDownMenu.selectField,
    width: theme.spacing(260)
  }
})
const StyledForm = withStyles(styles)(Form)

export default StyledForm

export function FormWithNav (props) {
  let history = useHistory();

  function selectStoreOrOrderTypeNav(storeNumber, orderType) {
    if(!orderType){
      history.push(`/store/${storeNumber}`);
    }
    else{
      history.push(`/store/${storeNumber}/type/${orderType}`)
    }
  }
  
  function onClearNav () {
    history.push(`/`)
  }
  return (
    <StyledForm selectStoreOrOrderTypeNav={selectStoreOrOrderTypeNav} onClearNav={onClearNav}{...props} />
  )
}