import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

export function GroupHeader ({ children , classes }) {
  return (
    <Grid container className={classes.root}>
      <strong className={classes.text}>{children}</strong>
    </Grid>
  )
}
const styles = theme => ({
  root: theme.typography.root,
  text: {
    ...theme.typography.text.small,
    ...theme.typography.container,
    minHeight: theme.spacing(18),
    paddingLeft: theme.spacing(4),
    background: theme.color.lighterGrey
  }
})
GroupHeader.propTypes = {
  children: PropTypes.string.isRequired
}
export default withStyles(styles)(GroupHeader)
