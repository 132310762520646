import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";
import Header from './Components/Header'
import CssBaseline from '@material-ui/core/CssBaseline'
import { PicklistNav } from './Scenes/Home/PicklistNav'

export default function Router() {
  return (
    <BrowserRouter>
      <Header/>
      <CssBaseline/>
      <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/store/:storeNumber/type/:orderType">
            <PicklistNav/>
          </Route>
          <Route path="/store/:storeNumber">
            <PicklistNav/>
          </Route>
          <Route path="/">
            <PicklistNav/>
          </Route>
        </Switch>
      </div>
    </BrowserRouter>
  )
}