import React from 'react'
import { Select, MenuItem, Input } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

export class DropDownMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: this.props.selected || ''
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.resetInputs !== this.props.resetInputs) {
      this.setState({ selected: '' })
    }
  }
  handleChange = (event, index) => {
    // set the selected option value for display
    this.setState({ selected: event.target.value })
    this.props.onChange(event.target.value)
  };
  renderOptions = () => {
    const placeHolder = [
      <MenuItem value="" disabled key={0}>
        {this.props.placeHolder}
      </MenuItem>
    ]
    
    const list = this.props.data.map((value, i) => {
      if (value && typeof value === 'object') {
        return (<MenuItem value={value.value} label={value.label} key={i + 1} divider={true} className={this.props.classes.menuList}>
            {value.label}
          </MenuItem>)
      }
      return (<MenuItem value={value} key={i + 1} divider={true} className={this.props.classes.menuList}>
        {value}
      </MenuItem>)
    })
    return placeHolder.concat(list)
  }
  render() {
    return (
      <Select
        value={this.state.selected}
        onChange={this.handleChange}
        disableUnderline={true}
        displayEmpty
        input={<Input value={this.state.selected}/>}
        className={this.props.className}
      >
        {this.renderOptions()}
      </Select>
    )
  }
}
DropDownMenu.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.string
      })
    ])
  ),
  onChange: PropTypes.func,
  className: PropTypes.string,
  resetInputs: PropTypes.bool,
  placeHolder: PropTypes.string,
  selected: PropTypes.string
}
const styles = theme => ({
  menuList: {
    height: theme.spacing(48),
    paddingLeft: theme.spacing(10)
  }
})

export default withStyles(styles)(DropDownMenu)