import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

// todo: this can be merge into groupheader
export function SubgroupHeader ({ departmentName, departmentCount, orderType , classes, timestamp }) {
  const departmentInfo = `${departmentName.toUpperCase()} : ${departmentCount}`
  const orderTypeName = ` ${orderType.replace(/_/g, ' ')}`
  const unit = ` item${departmentCount > 1 ? 's' : ''}`
  return (
    <div className={classes.root}>
      <div>
        <strong className={classes.text}>{departmentInfo}</strong>
        <strong className={classes.printOnly}>{orderTypeName}</strong>
        <strong className={classes.text}>{unit}</strong>
      </div>
      <div className={classes.timestamp}>{timestamp}</div>
    </div>
  )
}
const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  printOnly: {
    ...theme.typography.container,
    ...theme.typography.text.small,
    minHeight: theme.spacing(18),
    display: 'none'
  },
  text: {
    ...theme.typography.container,
    ...theme.typography.text.small,
    minHeight: theme.spacing(18),
    display: 'inline'
  },
  timestamp: {
    display: 'none'
  },
  '@media print': {
    printOnly: {
      ...theme.typography.container,
      ...theme.typography.text.small,
      minHeight: theme.spacing(18),
      display: 'inline'
    },
    timestamp: {
      display: 'inline'
    }
  }
})
SubgroupHeader.propTypes = {
  departmentCount: PropTypes.number.isRequired,
  departmentName: PropTypes.string.isRequired,
  orderType: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired
}
export default withStyles(styles)(SubgroupHeader)
