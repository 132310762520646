
const VARS_URI = '/vars.json'

let cachedData = {
  'TEST_VAR': 'TEST'
}

class Variables {
  lastFetched = null

  constructor () {
    this.fetchData()
  }

  /**
   * ASYNC
   * Fetch variables from vars.json, which is generated during deployment.
   */
  fetchData = async () => {
    try {
      const res = await fetch(VARS_URI)
      const json = await res.json()
      cachedData = json
      this.lastFetched = Date.now()
    } catch (err) {
      if (process.env.NODE_ENV !== 'test')
        console.error(err)
    }
  }

  /**
   * Get ALL variables deployed in vars.json.
   *
   * @returns Key Value pairs of all variables
   */
  getAllVariables = () => {
    return cachedData
  }

  /**
   * Get a variable from cached data.
   *
   * @param {string} kv Variable Key
   * @returns {*} Variable Value
   */
  getVariable = (kv) => {
    if (!cachedData) {
      return null
    }

    return cachedData[kv]
  }

  /**
   * ASYNC
   * Get a variable after fetch the newest data
   *
   * @param {string} kv Variable Key
   * @returns {*} Variable Value
   */
  getVariableAsync = async (kv) => {
    await this.fetchData()
    return this.getVariable(kv)
  }

}

export default new Variables()
