import React from 'react'
import { MenuItem, Button, Checkbox, Popover } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import PropTypes from 'prop-types'
import FirstEscalationIcon from '../../../Images/1stEscalation_BlackIcon.png'
import SecondEscalationIcon from '../../../Images/2ndEscalation_BlackIcon.png'

const HR_NAME = '---'

export const FILTER_OPTIONS = [
  { label: 'Priority' },
  { displayName: 'Past due 2+ days',
    value: 'pastDue' },
  { displayName: 'Yesterday',
    value: 'yesterday' },
  { displayName: 'Today',
    value: 'today' },
  { displayName: 'Tomorrow',
    value: 'tomorrow' },
  { displayName: 'Upcoming 2+ days',
    value: 'upcoming' },
  { displayName: HR_NAME },
  { label: 'Item Flags' },
  { displayName: 'Anniversary',
    tagName: 'A',
    value: 'anniversary' },
  { displayName: '1st Escalation',
    tagName: 'FE',
    value: 'firstEscalation',
    icon: FirstEscalationIcon },
  { displayName: '2nd Escalation',
    tagName: 'SE',
    value: 'secondEscalation',
    icon: SecondEscalationIcon },
  { displayName: 'Non Escalated',
    value: 'nonEscalated' }
]

export class FilterMenu extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selected: [],
      anchorEl: null,
      count: 0
    }
  }
  renderViewButton = () => (
    <div className={this.props.classes.menuFooter}>
      <Button
        type='button'
        variant='contained'
        className={this.props.classes.button}
        children='View Items'
        onClick={this.getFilters.bind(this)}/>
    </div>)
  getFilters = () => {
    this.setState({count: this.state.selected.length,
      anchorEl: null})
    this.props.getFilters(this.state.selected)
  }
  onClick = (event) => {
    const currentTargetValue = event.currentTarget.getAttribute('value')
    const prevSelected = this.state.selected
    let nextSelected = []
    if (prevSelected.includes(currentTargetValue)) {
      nextSelected = [ ...this.state.selected ] // make a separate copy of the array
      const index = nextSelected.indexOf(currentTargetValue)
      if (index !== -1) {
        nextSelected.splice(index, 1)
        this.setState({selected: nextSelected})
      }
    } else {
      nextSelected = [ ...prevSelected, currentTargetValue ]
    }
    this.setState({selected: nextSelected})
  }
  renderItem = (option, index) => {
    if (option.displayName === HR_NAME)
      return <hr key={index + 1} className={this.props.classes.horizontalLine}/>
    if (option.label) {
      return this.renderSimpleLabel(option, index)
    }
    return this.renderOptionWithCheckBox(option, index)
  }
  renderSimpleLabel = (option, index) => {
    return <MenuItem key={index + 1} className={this.props.classes.menuLabel}>
      {option.label}
    </MenuItem>
  }
  renderOptionWithCheckBox = (option, index) => {
    const displayTag = option.icon
      ? <span>{option.displayName} <img src={option.icon} alt="Icon" className={this.props.classes.indicator}/></span>
      : option.tagName ? <span>{option.displayName} <strong>({option.tagName})</strong></span> : <span>{option.displayName}</span>

    return (
      <MenuItem value={option.value} key={index + 1} className={this.props.classes.menuList} onClick={this.onClick.bind(this)}>
        <Checkbox
          checked={this.state.selected.includes(option.value)}/>
        {displayTag}
      </MenuItem>
    )
  }
  renderOptions = () => (
    FILTER_OPTIONS.map((option, index) => (this.renderItem(option, index)))
  )
  renderPopover = () => {
    return <div className={this.props.classes.menuWrapper}>
      {this.renderOptions()}
      {this.renderViewButton()}
    </div>
  }
  onChange = (event, index) => {
    // set the selected option value for display
    this.setState({ selected: event.target.value })
    // pass the selected option index back to the parent component
    // this.props.onChange(index.key)
    console.log(`Options are selected: ${JSON.stringify(this.state.selected)}`)
  }
  displayFilterCount = () => {
    const count = this.state.count === 0 ? '' : `(${this.state.selected.length})`
    return `Filter ${count}`
  }
  handleClick = (event) => {
    this.setState({anchorEl: event.currentTarget})
  }
  handleClose = () => {
    this.setState({anchorEl: null})
  }
  renderMenu = (open) => (
    <div className={this.props.classes.container}>
      <Button
        className={this.props.classes.selectField}
        disableRipple={true}
        aria-describedby={open ? 'filter-options' : null}
        onClick={this.handleClick}>
        {this.displayFilterCount()}
        <ArrowDropDownIcon className={this.props.classes.icon}/>
      </Button>
      <Popover
        id={open ? 'filter-options' : null}
        open={open}
        anchorEl={this.state.anchorEl}
        onClose={this.handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        children={this.renderPopover()}
      />
    </div>
  )
  render () {
    return this.props.isFilterEnabled ? this.renderMenu(Boolean(this.state.anchorEl)) : null
  }
}
FilterMenu.propTypes = {
  getFilters: PropTypes.func,
  isFilterEnabled: PropTypes.bool
}

const styles = theme => ({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'inline',
    marginRight: theme.spacing(20)
  },
  horizontalLine: {
    borderColor: '#FFFE',
    margin: '12px 14px'
  },
  menuFooter: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  menuLabel: {
    cursor: 'default',
    fontFamily: theme.fontFamily.primary,
    fontSize: theme.fontSize.secondary,
    fontWeight: 700,
    height: theme.spacing(35),
    paddingLeft: theme.spacing(15),
    paddingRight: theme.spacing(35),
    pointerEvents: 'none'
  },
  menuList: {
    height: theme.spacing(35),
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(35),
    fontSize: theme.fontSize.secondary,
    fontFamily: theme.fontFamily.primary
  },
  menuWrapper: {
    paddingTop: theme.spacing(20)
  },
  button: {
    ...theme.typography.button.primary,
    marginTop: theme.spacing(20),
    marginBottom: theme.spacing(20)
  },
  optionHeader: {
    ...theme.typography.text.primary,
    color: theme.color.primary,
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(15)
  },
  selectField: {
    ...theme.typography.dropDownMenu.filter,
    paddingLeft: theme.spacing(10),
    fontFamily: theme.fontFamily.primary,
    fontSize: theme.fontSize.primary,
    justifyContent: 'space-between',
    textTransform: 'none'
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  indicator: {
    height: theme.spacing(18),
    paddingLeft: theme.spacing(5),
    marginBottom: theme.spacing(-3)
  }
})

export default withStyles(styles)(FilterMenu)
