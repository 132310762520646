import React, {useState, useEffect} from 'react'

import VariablesContext from './VariablesContext'
import Variables from '../Services/util/vars'

const VariablesWrapper = (props) => {
  const [ vars, setVars ] = useState(Variables.getAllVariables())

  useEffect(() => {
    async function fetchVariables () {
      await Variables.fetchData()
      setVars(Variables.getAllVariables())
    }

    if (props.vars) {
      setVars(props.vars)
    } else {
      fetchVariables()
    }
  }, [ props ])

  return <VariablesContext.Provider value={vars}>
    {props.children}
  </VariablesContext.Provider>
}

export default VariablesWrapper
