import React from 'react'

import LoadingIndicator from './LoadingIndicator'
import { LinearProgress, CircularProgress, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PickList from './PickList'
import PropTypes from 'prop-types'
import { fetchPickTasks } from '../../../Services/api/index'
import { timeFormatter } from '../../../Services/util/index'
export class PicklistResults extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stores: [],
      pickTasks: {},
      displayOrders: false, // todo: optimize this flags
      finishedRendering: false,
      startLoading: false,
      displayError: false,
      storeNumber: '',
      orderType: '',
      timestamp: ''
    }
  }

  componentDidMount() {
    const {storeNumber, orderType} = this.props
    return this.loadPicklistForStoreAndOrderType(storeNumber, orderType)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.storeNumber !== this.props.storeNumber || prevProps.orderType !== this.props.orderType) {
      const {storeNumber, orderType} = this.props
      return this.loadPicklistForStoreAndOrderType(storeNumber, orderType)
    }
  }
  
  loadPicklistForStoreAndOrderType (storeNumber, orderType) {
    this.setState({ startLoading: true, finishedRendering: false, finishedFetching: false })
    return fetchPickTasks(storeNumber, orderType).then(response => {
      if (response.status === 200) {
        this.setState({
          pickTasks: response.data,
          displayOrders: true,
          finishedFetching: true,
          finishedRendering: !response.data.count,
          timestamp: timeFormatter.getLocaltimestamp(new Date())
        })
      } else {
        this.setState({
          displayError: true,
          displayOrders: true,
          finishedFetching: true
        })
      }
    })
  }
  
  renderEmptyListMessage() {
    return <div className={this.props.classes.emptyListMessage}><strong>No orders found.</strong></div>
  }
  
  onFinishedRendering = () => {
    console.log('on finished loading called')
    this.setState({ finishedRendering: true })
  }
  
  renderOrders() {
    if (this.state.pickTasks.count === 0) {
      return this.renderEmptyListMessage()
    } else {
      const pickListProps = {
        storeNumber: this.props.storeNumber,
        orderType: this.props.orderType,
        storeName: this.props.storeName,
        totalItemCount: this.state.pickTasks.count,
        pickTasks: this.state.pickTasks,
        onFinishedRendering: this.onFinishedRendering.bind(this),
        timestamp: this.state.timestamp
      }
      return <PickList {...pickListProps} />
    }
  }

  renderLoadingIndicator = () => {
    if (this.state.finishedRendering) {
      return <LinearProgress variant="determinate" value={100} />
    }

    if (this.state.startLoading && !this.state.finishedRendering) {
      return <Grid>
        <LoadingIndicator finishedFetching={this.state.finishedFetching} onFinishedLoading={this.onFinishedRendering.bind(this)}/>
        <Grid container item className={this.props.classes.root} justify="center" alignItems="center">
          <Grid item align="center" xs={12}>
            <CircularProgress />
            <div className={this.props.classes.spinningIndicator}>Loading results</div>
          </Grid>
        </Grid>
      </Grid>
    }

    if (!this.state.startLoading) {
      return <LinearProgress variant="determinate" value={0} />
    }
  }
  
  renderError () {
    if (this.state.displayError) {
      return (<div>Encountered error when fetching data</div>)
    }
    return null
  }
  render() {
    return(
      <div className={this.props.classes.container}>
        <div className={this.props.classes.linearIndicator}>
          <div className='not-printable'>{this.renderLoadingIndicator()}</div>
        </div>
        <div>
          {this.renderError()}
        </div>
        {(this.state.displayOrders && this.state.finishedFetching)?this.renderOrders() : null}
      </div>
    )
  }
}

PicklistResults.propTypes = {
  storeNumber: PropTypes.string,
  orderType: PropTypes.string,
  storeName: PropTypes.string
}
const styles = theme => ({
  container: {
    ...theme.typography.container,
    flexDirection: 'column'
  },
  linearIndicator: {
    marginTop: theme.spacing(31)
  },
  emptyListMessage: {
    ...theme.typography.text.primary,
    marginTop: theme.spacing(19)
  },
  root: {
    flexGrow: 1,
    height: theme.spacing(400),
    textAlign: 'center'
  },
  spinningIndicator: {
    padding: theme.spacing(10)
  }
})

export default withStyles(styles)(PicklistResults)