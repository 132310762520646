import React from 'react'
import CollapseButton from '../../../Images/collapse-button.svg'
import ExpandButton from '../../../Images/expand-button.svg'
import PropTypes from 'prop-types'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: theme.typography.root,
  img: {
    maxHeight: theme.spacing(21),
    maxWidth: theme.spacing(21)
  },
  button: {
    height: theme.spacing(36),
    marginRight: theme.spacing(20),
    flex: 1,
    display: 'inline'
  }
})

export function ExpandCollapseButtons(props) {
  const EXPANDED = 'expanded'
  const COLLAPSED = 'collapsed'
  const { classes, isExpanded } = props
  const handleToggleExpand = () => {
    props.onToggleExpand()
  }

  return (
    <ToggleButtonGroup
      value={isExpanded ? EXPANDED : COLLAPSED}
      exclusive
      onChange={handleToggleExpand}
      aria-label='expand collapse'
      size='small'
      className={classes.button}
    >
      <ToggleButton value={EXPANDED} aria-label='expanded' disabled={isExpanded}>
        <Tooltip title='Expand Dept'>
          <img src={ExpandButton} alt='Expand Dept' className={classes.img} />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value={COLLAPSED} aria-label='collapsed' disabled={!isExpanded}>
        <Tooltip title='Collapse Dept'>
          <img src={CollapseButton} alt='Collapse Dept' className={classes.img} />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

ExpandCollapseButtons.propTypes = {
  classes: PropTypes.object,
  isExpanded: PropTypes.bool.isRequired,
  onToggleExpand: PropTypes.func.isRequired
}

ExpandCollapseButtons.defaultProps = {
  onToggleExpand: () => console.log(`onToggleExpand()`)
}

export default withStyles(styles)(ExpandCollapseButtons)
