import moment from 'moment'

import { ESCALATION_LEVEL_1, ESCALATION_LEVEL_2 } from '../../Scenes/Home/Components/EscalationIndicator'

export const PRIORITY_COMPARISON_TYPE = {
  EQ_OR_BEFORE: 'EQ_OR_BEFORE',
  EQ: 'EQ',
  EQ_OR_AFTER: 'EQ_OR_AFTER'
}

const filter = {}

filter.isAnniversary = (item) => {
  return item.isAnniversary === 'true' || item.isAnniversary === true
}

filter.escalated = (item) => {
  return item.escalationLevel
}

filter.escalationLevel = (level) => (item) => {
  return item.escalationLevel === level
}

filter.nonEscalated = (item) => {
  return !filter.escalated(item)
}

filter.priority = (dateDelta, comparisonType) => (item) => {
  const targetDate = item.dueByDate || item.expiresDate
  if (!targetDate) return false

  const today = moment().startOf('day')
  const mTargetDate = moment(targetDate).startOf('day')

  const diff = mTargetDate.diff(today, 'day')

  switch (comparisonType) {
  case PRIORITY_COMPARISON_TYPE.EQ_OR_AFTER:
    return diff >= dateDelta
  case PRIORITY_COMPARISON_TYPE.EQ:
    return diff === dateDelta
  case PRIORITY_COMPARISON_TYPE.EQ_OR_BEFORE:
    return diff <= dateDelta
  default:
    return false
  }
}

const countReducer = (total, current) => {
  return current.count ? total + current.count : total
}

/**
 * Return filter function for OR operation
 *
 * @param {*} orFilterList Filter List for OR condition
 * @returns filter function
 */
const orFilter = (orFilterList) => (item) => {
  for (const filter of orFilterList) {
    if (typeof filter === 'function' && filter(item)) {
      return true
    }
  }
  return false
}

const itemFilter = (filterGroups) => (item) => {
  for (const kv in filterGroups) {
    if (orFilter(filterGroups[kv])(item) === false) {
      return false
    }
  }
  return true
}

filter.byItems = (items, filterGroups) => {
  return items.filter(itemFilter(filterGroups))
}

filter.byDepartments = (departments, filterGroups) => {
  return departments
    .map((department) => {
      const { departmentId, name, orderCount } = department
      const items = filter.byItems(department.items, filterGroups)
      return { count: items.length,
        departmentId,
        items,
        name,
        orderCount }
    })
    .filter((department) => (department.count > 0))
}
filter.byOrderTypes = (orderTypes, filterGroups) => {
  return orderTypes
    .map((orderType) => {
      const departments = filter.byDepartments(orderType.departments, filterGroups)
      const count = departments.reduce(countReducer, 0)
      return { count,
        departments,
        name: orderType.name }
    })
    .filter((orderType) => (orderType.count > 0))
}
filter.byPickTasks = (pickTasks, filterGroups) => {
  const filteredList = filter.byOrderTypes(pickTasks.orderTypes, filterGroups)
  const count = filteredList.reduce(countReducer, 0)
  return { count,
    orderTypes: filteredList }
}

const FILTER_GROUP = {
  PRIORITY: 'PRIORITY',
  ITEM_FLAG: 'ITEM_FLAG'
}

const FILTER_GROUP_MAP = {
  pastDue: FILTER_GROUP.PRIORITY,
  yesterday: FILTER_GROUP.PRIORITY,
  today: FILTER_GROUP.PRIORITY,
  tomorrow: FILTER_GROUP.PRIORITY,
  upcoming: FILTER_GROUP.PRIORITY,
  anniversary: FILTER_GROUP.ITEM_FLAG,
  firstEscalation: FILTER_GROUP.ITEM_FLAG,
  secondEscalation: FILTER_GROUP.ITEM_FLAG,
  nonEscalated: FILTER_GROUP.ITEM_FLAG
}

const FILTER_FUNC_MAP = {
  pastDue: filter.priority(-2, PRIORITY_COMPARISON_TYPE.EQ_OR_BEFORE),
  yesterday: filter.priority(-1, PRIORITY_COMPARISON_TYPE.EQ),
  today: filter.priority(0, PRIORITY_COMPARISON_TYPE.EQ),
  tomorrow: filter.priority(1, PRIORITY_COMPARISON_TYPE.EQ),
  upcoming: filter.priority(2, PRIORITY_COMPARISON_TYPE.EQ_OR_AFTER),
  anniversary: filter.isAnniversary,
  firstEscalation: filter.escalationLevel(ESCALATION_LEVEL_1),
  secondEscalation: filter.escalationLevel(ESCALATION_LEVEL_2),
  nonEscalated: filter.nonEscalated,
  escalated: filter.escalated
}

filter.getFilterFuncList = (filterList) => {
  // Group filter functions.
  // The filters in the group will use OR operation, The groups will use AND operation.
  const filterGroups = {}
  filterList.forEach(filterName => {
    const filterGroupName = FILTER_GROUP_MAP[filterName] || 'nogroup'
    const filterFunction = FILTER_FUNC_MAP[filterName]
    if (Array.isArray(filterGroups[filterGroupName])) {
      filterGroups[filterGroupName].push(filterFunction)
    } else {
      filterGroups[filterGroupName] = [ filterFunction ]
    }
  })
  return filterGroups
}

export default filter
