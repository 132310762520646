import React from 'react'
import { AppBar, Typography, Toolbar, withStyles, Divider } from '@material-ui/core'
import nordstromIcon from '../Images/nordstrom-2019-black-rgb.png'

export function Header ({ classes }) {
  function renderLogo () {
    return (
      <div className={classes.logContainer}>
        <img src={nordstromIcon} alt="Nordstrom" className={classes.logo}/>
      </div>
    )
  }

  function renderHeaderText () {
    return (
      <Typography variant='h5' className={classes.header}>
        In-Store Fulfillment Queue
      </Typography>
    )
  }
  function renderDivider () {
    return <Divider orientation='vertical' className={classes.divider}/>
  }
  return (
    <div className={`${classes.root} not-printable`}>
      <AppBar position='static'>
        <Toolbar className={classes.toolbar}>
          {renderLogo()}
          {renderDivider()}
          {renderHeaderText()}
        </Toolbar>
      </AppBar>
    </div>
  )
}

const styles = theme => ({
  root: theme.typography.root,
  toolbar: {
    paddingLeft: theme.spacing(30),
    paddingRight: theme.spacing(30)
  },
  divider: theme.shape.dividerVertical,
  header: theme.typography.header,
  logo: {
    width: theme.spacing(134),
    minHeight: theme.spacing(17)
  },
  logContainer: {
    flewGrow: 1,
    minHeight:  theme.spacing(20),
    paddingTop:  theme.spacing(3)
  }
})

export default withStyles(styles)(Header)