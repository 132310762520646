import React from 'react';
import './App.css';
import theme from './Scenes/Home/Components/theme'
import { ThemeProvider } from '@material-ui/styles'
import Router from './Router'
import VariablesWrapper from './Components/VariablesWrapper'

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <VariablesWrapper>
        <Router/>
      </VariablesWrapper>
    </ThemeProvider>
  )
}
