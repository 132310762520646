import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Divider } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ItemPanel from './ItemPanel'

const styles = theme => ({
  root: theme.typography.root,
  labels: theme.typography.container,
  divider: theme.shape.divider,
  doubleDividerTop: theme.shape.doubleDividerTop,
  doubleDividerBottom: theme.shape.doubleDividerBottom,
  label: theme.typography.text.secondary,
  indicatorColumn: {
    maxWidth: theme.spacing(30)
  }
})

export function ItemList ({ items, classes }) {
  const labels = [
    { children: 'Due By', gridItemSize: 1, gridItemXSSize: 12 },
    { children: 'Order', gridItemSize: 2, gridItemXSSize: 12 },
    { children: 'Brand', gridItemSize: 1, gridItemXSSize: 12 },
    { children: 'Item Description', gridItemSize: 2, gridItemXSSize: 12 },
    { children: 'UPC', gridItemSize: 2, gridItemXSSize: 12 },
    { children: 'Size', gridItemSize: 1, gridItemXSSize: 12 },
    { children: 'Color', gridItemSize: 1, gridItemXSSize: 12 },
    { children: 'Price', gridItemSize: 1, gridItemXSSize: 12 },
    { children: '', gridItemSize: 1, gridItemXSSize: 12 },
  ]
  function renderDivider (isLastItem) {
    const doubleDivider = [classes.doubleDividerTop, classes.doubleDividerBottom].map((className, index) => (
      <Divider className={className} key={index} />
    ))
    const singleDivider = <Divider className={classes.divider} />
    return isLastItem ? doubleDivider : singleDivider
  }
  function buildClassName (key) {
    if (key === labels.length - 1 || key === labels.length - 2) {
      return `${classes.label} ${classes.indicatorColumn}`
    }
  }
  function renderLabels () {
    return labels.map(({ children, gridItemXSSize, gridItemSize }, index) => (
      <Grid item xs={gridItemXSSize} sm={gridItemSize} className={buildClassName(index)} key={index}>
        <div className={classes.label}>{children}</div>
      </Grid>
    ))
  }
  function renderItemPanels () {
    return items.map((item, index) => {
      return (
        <div key={index}>
          <ItemPanel item={item} />
          {renderDivider(index === items.length - 1)}
        </div>
      )
    })
  }

  return (
    <div className={classes.root}>
      <Grid container direction='row' justify='space-between' alignItems='center'>
        {renderLabels()}
      </Grid>
      <Divider className={classes.divider} />
      {renderItemPanels()}
    </div>
  )
}

ItemList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      orderId: PropTypes.string.isRequired,
      expiresDate: PropTypes.string.isRequired,
      dueByDate: PropTypes.string.isRequired,
      brand: PropTypes.string.isRequired,
      upc: PropTypes.string.isRequired,
      itemName: PropTypes.string.isRequired,
      color: PropTypes.string,
      size: PropTypes.string,
      storePrice: PropTypes.string,
      isAnniversary: PropTypes.bool
    })
  ).isRequired
}

export default withStyles(styles)(ItemList)
