import React, { useReducer, useEffect } from 'react'
import { LinearProgress } from '@material-ui/core'

/**
 * functional component contains a self updated percentage completed, and invokes a callback function when it reaches 100
 * @param finishedFetching
 * @param onFinishedLoading
 * @return {*}
 * @constructor
 */
export default function LoadingIndicator({ finishedFetching, onFinishedLoading }) {
  // complete, which is a number that under 100, represents the completed percentage
  const [completed, dispatch] = useReducer(reducer, 0);
  // the logic that updates the complete
  function reducer(state, action) {
    if (action.type === 'load') {
      if (state < 70 && !finishedFetching) {
        return state + 10 // a slow progress while fetching
      } else if (state < 100 && finishedFetching) {
        return 100 // immediately speed up the progress if the fetch is completed
      } else if (state >= 100) {
        return 100 // remain 100 percent before removed
      } else {
        return state // stay at 70 percent
      }
    } else {
      throw new Error('Unknown action type.');
    }
  }

  useEffect(() => {
    // run every second to dispatch a load action
    const actions = setInterval(() => {
      if (completed <= 100) {
        dispatch({ type: 'load' })
      }
    }, 1000);
    return () => clearInterval(actions) // self cleaning
  }, [dispatch, completed]); // add dependencies

  return (
    <div>
      <LinearProgress variant="determinate" value={completed} />
    </div>
  );
}
