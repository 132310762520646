import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import DropDownMenu from '../../../Components/DropDownMenu'
import PrintIcon from '@material-ui/icons/Print'
import FilterMenu, { FILTER_OPTIONS } from './FilterMenu'
import { isAnniversaryEnabled } from '../../../Services/api/index'
import ExpandCollapseButtons from './ExpandCollapseButtons'
import { pluralize } from '../../../Services/util'


const styles = theme => ({
  root: theme.typography.root,
  textContainer: {
    minHeight: theme.spacing(40),
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  text: theme.typography.text.primary,
  button: {
    ...theme.typography.button.secondary,
    width: 43,
    minWidth: 43,
    padding: '9px 0px'
  },
  optionContainer: {
    ...theme.typography.container,
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  dropDownMenu: {
    ...theme.typography.dropDownMenu.selectField,
    width: theme.spacing(187)
  }
})

const FILTER_LABEL_MAP = {}

FILTER_OPTIONS.forEach(filter => {
  if (filter.value) {
    FILTER_LABEL_MAP[filter.value] = filter.displayName || filter.value
  }
})

function buildFilterCountText (timestamp, selectedFilters, filteredItemCount) {
  if (selectedFilters) {
    let selectedFiltersText = selectedFilters.map(filterValue => FILTER_LABEL_MAP[filterValue]).sort().join(', ')
    return <>
      {'SHOWING '}
      <b>{`${selectedFilters.length} ${pluralize('Filter', selectedFilters.length).toUpperCase()}`}</b>
      {`: ${selectedFiltersText}| ${filteredItemCount} ${pluralize('Filtered Item', filteredItemCount)} | As of ${timestamp}`}
    </>
  }
  return `As of ${timestamp}`
}
export function ReportHeader (props) {
  const { storeNumber, storeName, orderType, totalOrderCount, totalItemCount, onChange, classes, departments, isExpanded } = props
  const boldText = `Store ${storeNumber} - ${storeName} | ${totalOrderCount} ${orderType.toUpperCase()} ${pluralize('Order', totalOrderCount)} | ${totalItemCount} items`
  function showPrintPopupWindow () {
    window.print()
  }
  return (
    <div className={classes.root}>
      <Grid container direction='row' justify='space-between' alignItems='center'>
        <Grid item xs={12} sm={7} className={classes.textContainer}>
          <div className={classes.text}>In-Store Fulfillment Results</div>
          <div><strong className={classes.text}>{boldText}</strong></div>
          <hr/>
          <div className={classes.text}>{buildFilterCountText(props.timestamp, props.selectedFilters, props.filteredItemCount)}</div>
        </Grid>
        <Grid item xs={12} sm={5} className={props.classes.optionContainer} class='not-printable'>
          <DropDownMenu
            data={departments}
            placeHolder='Select a department'
            className={props.classes.dropDownMenu}
            onChange={onChange.bind(this)}
          />
          <FilterMenu
            isFilterEnabled={isAnniversaryEnabled()}
            getFilters={props.getFilters}/>
          <ExpandCollapseButtons
            onToggleExpand={props.onToggleExpand}
            isExpanded={isExpanded}
          />
          <Button
            variant='outlined'
            className={classes.button}
            disableRipple={true}
            onClick={showPrintPopupWindow.bind(this)}>
            <PrintIcon style={{ fontSize: 24 }}/>
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

ReportHeader.propTypes = {
  storeNumber: PropTypes.string.isRequired,
  storeName: PropTypes.string.isRequired,
  orderType: PropTypes.string.isRequired,
  totalOrderCount: PropTypes.number,
  totalItemCount: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.object,
  timestamp: PropTypes.string.isRequired,
  onToggleExpand: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired
}

ReportHeader.defaultProps = {
  onChange: (arg) => console.log(`Selected ${arg}`),
  onToggleExpand: (arg) => console.log(`Selected ${arg}`)
}

export default withStyles(styles)(ReportHeader)

