import { createMuiTheme } from '@material-ui/core/styles'
import createTypography from '@material-ui/core/styles/createTypography'
import createPalette from '@material-ui/core/styles/createPalette'
import { grey } from '@material-ui/core/colors'

const PRIMARY = '#000000'
const PRIMARY_FONT_COLOR = '#393939'
const WHITE = '#ffffff'
const DARK_GREY = '#979797'
const GREY = '#999999'
const LIGHT_GREY = '#c4c4c4'
const LIGHTER_GREY = '#dcdcdc'
const TOOLTIP_GREY = '#737373'
const PRIMARY_FONT_SIZE = 15
const SECONDARY_FONT_SIZE = 14
const SMALL_FONT_SIZE = 13
const PRIMARY_FONT_FAMILY = 'Open Sans'

const palette = createPalette({
  primary: {
    main: PRIMARY
  },
  secondary: grey
})
const customShape = {
  divider: {
    marginTop: '12px',
    marginBottom: '12px',
    background: GREY
  },
  dividerVertical: {
    background: DARK_GREY,
    height: '18px',
    width: '1px',
    marginLeft: '9px',
    marginRight:  '9px',
    marginTop:  '2px'
  },
  doubleDividerTop: {
    marginTop: '12px',
    marginBottom: '2px',
    background: GREY
  },
  doubleDividerBottom: {
    marginBottom: '34px',
    background: GREY
  },
  barCode: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  }
}
const typography = createTypography(palette, {
  fontSize: 11,
  header: {
    fontFamily: PRIMARY_FONT_FAMILY,
    fontSize: 19.6,
    fontWeight: 'bold'
  },
  text: {
    primary: {
      color: PRIMARY_FONT_COLOR,
      fontSize: PRIMARY_FONT_SIZE,
      fontFamily: PRIMARY_FONT_FAMILY,
      whiteSpace: 'unset'
    },
    secondary: {
      color: PRIMARY_FONT_COLOR,
      fontSize: SECONDARY_FONT_SIZE,
      fontFamily: PRIMARY_FONT_FAMILY,
      whiteSpace: 'unset'
    },
    small: {
      color: PRIMARY_FONT_COLOR,
      fontSize: SMALL_FONT_SIZE,
      fontFamily: PRIMARY_FONT_FAMILY,
      whiteSpace: 'unset'
    },

  },
  root: {
    flewGrow: 1
  },
  container: {
    flex: 1,
    display: 'flex'
  },
  button: {
    primary: {
      backgroundColor: PRIMARY,
      color: WHITE,
      width: '141px',
      height: '36px',
      fontSize: 13.7,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: PRIMARY
      }
    },
    secondary: {
      backgroundColor: WHITE,
      color: PRIMARY_FONT_COLOR,
      borderWidth: 1,
      borderColor: LIGHT_GREY,
      width: '167px',
      height: '36px',
      fontSize: 13.7,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: WHITE
      }
    },
    link: {
      fontSize: 14,
      color: PRIMARY_FONT_COLOR,
      width: '97px',
      height: '36px',
      fontFamily: 'Open Sans',
      textTransform: 'none',
      textDecorationLine: 'underline'
    }
  },
  dropDownMenu: {
    menuList: {
      style: {
        maxHeight: '50%',
        minHeight: '240px',
        top: 50
      }
    },
    selectField: {
      width: '263px',
      height: '40px',
      borderRadius: 5,
      backgroundColor: 'rgba(0,0,0,0.08)',
      margin: '0',
      marginRight: '20px',
      paddingLeft: '10px'
    },
    filter: {
      width: '118px',
      height: '36px',
      borderRadius: 4,
      border: 'solid 1px #c4c4c4',
      backgroundColor: 'rgba(98, 2, 238, 0)',
    }
  },
  form: {
    formLabel: {
      color: PRIMARY,
      fontSize: SECONDARY_FONT_SIZE,
      fontWeight: 'bold',
      marginBottom: '9px',
      marginTop: '9px',
      fontFamily: PRIMARY_FONT_FAMILY
    }
  }
})
export default createMuiTheme({
  palette: palette,
  typography: typography,
  shape: customShape,
  spacing: (number) => (`${number}px`),
  fontFamily: {
    primary: PRIMARY_FONT_FAMILY
  },
  fontSize: {
    primary: PRIMARY_FONT_SIZE,
    secondary: SECONDARY_FONT_SIZE,
    small: SMALL_FONT_SIZE,
  },
  color: {
    primary: PRIMARY,
    white: WHITE,
    darkGrey: DARK_GREY,
    grey: GREY,
    lightGrey: LIGHT_GREY,
    lighterGrey: LIGHTER_GREY,
    tooltip: TOOLTIP_GREY
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "1em",
        color: WHITE
      }
    }
  }
})