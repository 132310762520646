const timeFormatter = {}

const WEEK_DAY = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

timeFormatter.getLocaltimestamp = (date) => {
  const weekDay = WEEK_DAY[date.getDay()]
  const time = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).split(' ')
  
  const localDate = date.toLocaleDateString()
  return `${time[0]} ${time[1].toLowerCase()}, ${weekDay} ${localDate}`
}

module.exports = timeFormatter
