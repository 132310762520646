import React from 'react'
import errorIcon from '../../../Images/ErrorIcon_Red.svg'
import { FormWithNav } from './Form'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import PicklistResults from './PicklistResults'
import { orderTypes } from '../../../Services/api'

export class Body extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stores: [],
      storeNumber: this.props.storeNumber || '',
      orderType: this.props.orderType || '',
      isSubmitted: false
    }
    this.onSubmit = this.onSubmit.bind(this)
  }
  componentDidUpdate(prevProps) {
    if (prevProps.storeNumber !== this.props.storeNumber || prevProps.orderType !== this.props.orderType) {
      const {storeNumber, orderType} = this.props
      return this.setState({ storeNumber, orderType })
    }
  }
  renderInstructions = () => (
    <p className={this.props.classes.instruction} >
      To view a list of open in-store orders, complete these fields and tap <strong>View Results</strong>.
    </p>
  )
  renderNoSelection() {
    let errorField = ''
    if ((!this.state.storeNumber || this.state.storeNumber === 'null')  && !!this.state.orderType) {
      errorField = 'a store'
    } else if (!!this.state.storeNumber && !this.state.orderType) {
      errorField = 'an order type'
    } else {
      errorField = 'a store and order type'
    }
    if ((!this.state.orderType || (!this.state.storeNumber || this.state.storeNumber === 'null')) && this.state.isSubmitted) {
      return (
        <div>
          <img
            src={errorIcon}
            alt={"Error Icon"}
            className={this.props.classes.errorIcon}
          />
          <span className={this.props.classes.noSelection}>
            Select {errorField}.
          </span>
        </div>
        )
    }
  }
  onClear = () => {
    this.setState({ storeNumber: '', orderType: '', isSubmitted: false })
  }

  onSubmit = ({ storeNumber, orderType }) => {
    this.setState({ isSubmitted: true })
    if (!storeNumber || !orderType) {
      console.debug(`storeNumber: ${storeNumber}, orderType: ${orderType} has been selected.`)
    } else {
      this.setState({ storeNumber, orderType })
    }
  }
  fetchStores = () => {
    this.props.getStores()
      .then(stores => {
        if (stores !== null) {
          this.setState({stores})
        } else {
          // couldn't get stores
        }
      })
  }
  componentDidMount() {
    this.fetchStores()
  }
  
  renderForm() {
    return(
      <FormWithNav
        stores={this.state.stores}
        orderTypes={orderTypes}
        onSubmit={this.onSubmit.bind(this)}
        onClear={this.onClear.bind(this)}
        storeNumber={this.state.storeNumber}
        orderType={this.state.orderType}
      />
    )
  }
  getStoreName = (storeNumber) => {
    let storeName = ''
    const stores = this.state.stores
    for (let i = 0; i < stores.length; i++) {
      if (`${stores[i].storeNumber}` === `${storeNumber}`) {
        storeName = stores[i].storeName
        break
      }
    }
    return storeName
  }
  renderPicklistResults() {
    const {storeNumber, orderType} = this.state
    const storeName = this.getStoreName(storeNumber)
    return (<PicklistResults storeNumber={storeNumber} storeName={storeName} orderType={orderType}/>)
  }

  render() {
    return(
      <div className={this.props.classes.container}>
        <div className='not-printable'>
          {this.renderInstructions()}
          {this.renderNoSelection()}
          {this.renderForm()}
        </div>
        <div>
          {((!!this.state.storeNumber && this.state.storeNumber !== 'null') && !!this.state.orderType) && this.renderPicklistResults()}
        </div>
      </div>
    )
  }
}

Body.propTypes = {
  getStores: PropTypes.func,
  storeNumber: PropTypes.string,
  orderType: PropTypes.string
}
const styles = theme => ({
  container: {
    ...theme.typography.container,
    flexDirection: 'column'
  },
  instruction: {
    ...theme.typography.text.primary,
    marginTop: theme.spacing(40),
    marginBottom: theme.spacing(33),
  },
  noSelection: {
    ...theme.typography.text.primary,
    display: 'inline-block',
    verticalAlign: 'middle',
    color: 'red',
    marginBottom: theme.spacing(10)
  },
  errorIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginBottom: theme.spacing(10),
    marginRight: theme.spacing(10)
  }
})

export default withStyles(styles)(Body)