import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import escalationIndicatorLevelOne from '../../../Images/1stEscalationFlag.png'
import escalationIndicatorLevelTwo from '../../../Images/2ndEscalationFlag.png'
import Tooltip from '@material-ui/core/Tooltip';
import { Grid } from '@material-ui/core'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'

const tooltipStyles = theme => ({
  tooltip: {
    backgroundColor: (theme.color && theme.color.tooltip),
    color: 'rgb(115, 115, 115, 0.5)',
    minWidth: theme.spacing(370),
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10)
  }
})
export const ESCALATION_LEVEL_1 = 'ESCALATION_LEVEL_1'
export const ESCALATION_LEVEL_2 = 'ESCALATION_LEVEL_2'

const EscalationInfo = withStyles(tooltipStyles)(Tooltip)
const renderEmployeeInitial = ({ employeeFirstName, employeeLastName }) => (
  `${employeeFirstName.substring(0,1).toUpperCase()}${employeeLastName.substring(0,1).toUpperCase()}`
)

export function EscalationIndicator({ escalationLevel, escalationMetadata, itemId, classes }) {
  if (!escalationLevel || !escalationMetadata) {
    return null
  }
  const hasMetadataForEscalationLevel = (level) => (escalationMetadata[level])
  const renderTitleHeader = (level) => {
    if (!hasMetadataForEscalationLevel(level)) {
      return null
    }
    const titleHeader = level === ESCALATION_LEVEL_1
      ? '1st check'
      : '2nd check'
    return <Grid item xs={3} sm={3} key={`${itemId}-${level}-title-header`} className={classes.gridRow}>
      <span className={`${classes.titleText} ${classes.boldText}`}>
        {`${titleHeader}`}
      </span>
    </Grid>
  }
  const renderDividerBetweenMetadata = () => (
    escalationLevel === ESCALATION_LEVEL_2
      ? <Grid item xs={12} sm={12} key={`${itemId}-space`} className={classes.space} />
      : null
  )
  const renderActionEmployeeInfo = (level) => {
    if (!hasMetadataForEscalationLevel(level)) {
      return null
    }
    const escalatedBy = escalationMetadata[level].escalatedBy
    return <Grid item xs={9} sm={9} key={`${itemId}-${level}-action-emp-info`} className={classes.gridRow}>
      <span className={`${classes.titleText} ${classes.boldText}`}>
        {`| ${renderEmployeeInitial(escalatedBy)} (Employee #${escalatedBy.employeeId})`}
      </span>
    </Grid>
  }
  const renderEscalationTime = (level) => {
    if (!hasMetadataForEscalationLevel(level)) {
      return null
    }
    const escalationTime = escalationMetadata[level].escalatedAt
    return <Grid item xs={3} sm={3} key={`${itemId}-${level}-escalation-time`} className={classes.gridRow}>
      <span className={classes.titleText}>
        {dateFormat(escalationTime, 'h:MMtt')}
      </span>
    </Grid>
  }
  const renderDeptEmployeeInfo = (level) => {
    if (!hasMetadataForEscalationLevel(level)) {
      return null
    }
    const deptEmployeeInfo = escalationMetadata[level].deptEmployeeName
      ? 'Checked with dept. employee'
      : 'Couldn\'t find someone to help.'
    return <Grid item xs={9} sm={9} key={`${itemId}-${level}-dept-emp-info`} className={classes.gridRow}>
      <span className={classes.titleText}>
        {deptEmployeeInfo}
      </span>
    </Grid>
  }
  const renderToolTipText = () => {
    return <React.Fragment>
      <Grid container justify={'space-between'} alignItems={'center'} direction={'row'}>
        {renderTitleHeader(ESCALATION_LEVEL_2)}
        {renderActionEmployeeInfo(ESCALATION_LEVEL_2)}
        {renderEscalationTime(ESCALATION_LEVEL_2)}
        {renderDeptEmployeeInfo(ESCALATION_LEVEL_2)}
        {renderDividerBetweenMetadata()}
        {renderTitleHeader(ESCALATION_LEVEL_1)}
        {renderActionEmployeeInfo(ESCALATION_LEVEL_1)}
        {renderEscalationTime(ESCALATION_LEVEL_1)}
        {renderDeptEmployeeInfo(ESCALATION_LEVEL_1)}
      </Grid>
    </React.Fragment>
  }
  const indicatorImgSource = escalationLevel === ESCALATION_LEVEL_1
    ? escalationIndicatorLevelOne
    : escalationIndicatorLevelTwo
  return <EscalationInfo title={renderToolTipText()} placement={'bottom-end'}>
    <img
      src={indicatorImgSource}
      alt={"Escalation indicator"}
      aria-describedby={"Escalation indicator"}
      className={classes.escalationIndicator}
      id={'escalation-indicator'}
    />
  </EscalationInfo>
}
const styles = theme => ({
  escalationIndicator: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    height: theme.spacing(30),
    width: theme.spacing(30),
    marginBottom: theme.spacing(-12)
  },
  titleText: {
    ...theme.typography.text.small,
    color: theme.color.white
  },
  boldText: {
    fontWeight: 'bold'
  },
  titleMessageContainer: {
    minWidth: theme.spacing(370),
    height: theme.spacing(126),
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10)
  },
  marginBottom: {
    marginBottom: theme.spacing(15)
  },
  space: {
    height: theme.spacing(15)
  },
  gridRow: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
})
EscalationIndicator.propTypes = {
  escalationLevel: PropTypes.string,
  itemId: PropTypes.string,
  escalationMetadata: PropTypes.shape({
    escalatedBy: PropTypes.shape({
      employeeFirstName: PropTypes.string,
      employeeLastName: PropTypes.string,
      employeeId: PropTypes.string
    }),
    escalatedAt: PropTypes.string
  }),
  classes: PropTypes.any
}
export default withStyles(styles)(EscalationIndicator)